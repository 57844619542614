import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CSVLink } from "react-csv"

import { Flex, Heading, Paragraph } from 'reakit'

import { Container, Sidebar, Body, Footer, Modal } from '../../../components/layouts'

import { DynamicTable, Card, Loader, Image, Button, Link } from '../../../components/ui'

import { getUsers, refreshToken } from '../../../factory'

import currencyFormat from '../../../util/currencyFormat'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

class Donors extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			update: false,
			donorDonations: [],
			userHidden: false,
			donorHidden: false,
			guestHidden: false
		}
	}

	componentDidMount() {
		this.props.refreshToken(this.props.reToken).then((res)=> {
			this.props.getUsers(res)
		})
	}

	filterUsers(userList) {
		var complete = []
		var incomplete = []

		if (userList === null) {
			return {complete, incomplete}
		}

 		complete = userList.filter((user) => user.is_donating || (user.has_payment_account && user.monthly_minimum !== 0))
		incomplete = userList.filter((user) => !(user.is_donating || (user.has_payment_account && user.monthly_minimum !== 0)))

		return {complete, incomplete}
	}

	renderDonorType(type) {
		switch (type) {
		case 0: return ''
		case 1: return "Round Ups"
		case 2: return "Daily + Round Ups"
		default: return "Daily"
		}
	}

	renderWhyIncomplete(donor) {
		var reason = []

		// general issues
		if (!donor.donor_type) {
			reason.push('No donor type')
		}
		if (!donor.has_payment_account) {
			reason.push('No payment account')
		}

		// round up issues || both
		if ((donor.donor_type === 1 || donor.donor_type === 2) && !donor.has_tracked_accounts) {
			reason.push('The donor has selected roundup donations, but does not have any tracked accounts.')
		}

		// daily donation issues || both
		if (donor.donor_type >= 2 && !donor.daily_donation_sum) {
			reason.push('The donor has selected daily donation, but has not set an amount to give.')
		}


		return (
			<Modal
				containterStyle={{minWidth: '50%'}}
				button={{text: 'Learn More', width: '8em'}}
				style={{justifyContent: 'flexStart'}}
			>
				<Flex row justifyContent="space-between">
					<Heading as="h5">{donor.first_name} does not have a complete user account</Heading>
				</Flex>
				<Heading as="h6">Reasons for incomplete account</Heading>
				<ul>
					{reason.map(r => <li><Paragraph>{r}</Paragraph></li>)}
				</ul>
			</Modal>

		)
	}

	render() {
		const {pending, organization, users, error} = this.props
		const {userHidden, donorHidden, donorDonations} = this.state

		if (pending) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row marginBottom="2em" width="100%">
									<Card padding="1em" width="100%">
										<Flex row justifyContent="space-between">
											<Flex style={{flex: 3}}>
												<div style={{display: 'flex'}}>
													<Heading as="h3" margin="0 0 0.5em 0">Loading Users...</Heading>
													<Loader/>
												</div>
											</Flex>
										</Flex>
										<DynamicTable data={donorDonations} columns={[
											{ Header: 'First Name', accessor: 'f_name' },
											{ Header: 'Last Name', accessor: 'l_name' },
											{ Header: 'Phone', accessor: 'phone' },
											{ Header: 'Email', accessor: 'email' },
										]}
										sortBy={[
											{
												id: "total_donated",
												desc: true
											}
										]}
										paginate
										maxRows={20}
										/>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}


		if (error) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Uh Oh! We had an error getting your users.</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading textAlign="center" as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Please call (512)516-9979 and let them know the following error occured:</Heading>
												<Paragraph style={{textAlign: 'center', marginRight: 50}}>{error}</Paragraph>
											</Flex>
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		if (!this.props.pending && this.props.organization && this.props.organization.donor_qty === 0 ) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Uh Oh! You don't have any users yet...</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											<div style={{width: '100%', marginBottom: '2em'}}>
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px" style={{textAlign: 'center'}}>Your first step is to add some users to the app.</Heading>
													<Paragraph style={{textAlign: 'center', marginBottom: '2em'}}>Send the link below to supporters, volunteers, or donors to get them #Flourishing. Remember, each donor generates about $25/mo! </Paragraph>
												</Flex>
												<Flex row width="50%" justifyContent="center" paddingRight="1em" margin="auto" >
													<Link href={theme.footer.appleStore.link}>
														<Image
															src={theme.footer.appleStore.image}
															height="48px"
															maxHeight="48px"
														/>
													</Link>
													<Link href={theme.footer.playStore.link}>
														<Image
															src={theme.footer.playStore.image}
															height="48px"
															maxWidth="150px"
															maxHeight="48px"
															style={{margin: "auto", marginLeft: '3em'}}
														/>
													</Link>
												</Flex>
												<Link href={'https://flourish.app.link/wKFzDRIsxV'}>
													<Paragraph>https://flourish.app.link/wKFzDRIsxV</Paragraph>
												</Link>
											</div>
											<Flex column width="100%" justifyContent={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
												<Paragraph>Need some help? Call us at (737)228-3586, or send us an email at <a href="mailto:nonprofits@flourishchange.com">nonprofits@flourishchange.com</a></Paragraph>
											</Flex>
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}


		const {complete, incomplete} = this.filterUsers(users)
		const hasComplete = complete && complete.length > 0
		const hasIncomplete = incomplete && incomplete.length > 0

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>

							{hasIncomplete &&
						<Flex row marginBottom="2em" width="100%">
							<Card padding="1em" width="100%">
								<Flex row justifyContent="space-between">
									<Flex style={{flex: 3}}>
										<div style={{display: 'flex'}}>
											<Heading as="h3" margin="0 0 0.5em 0">{incomplete && incomplete.length} Incomplete User Accounts</Heading>
											{pending && <Loader/>}
										</div>
										{!pending &&
											<Button style={{marginLeft: 10, maxWidth: 150}}
												onClick={()=>this.setState({userHidden: !userHidden})}
											>{userHidden ? "Show" : "Hide"}</Button>
										}
									</Flex>
									{incomplete && incomplete.length > 0  && <CSVLink
										filename={`${organization && organization.title.replace(/ /g, '_')}_Flourish_incomplete_users.csv`}
										style={{marginLeft: 15,
											fontFamily: theme.fonts.primary,
											textDecoration: 'none'
										}}
										data={incomplete}>
									Download
									</CSVLink>}
								</Flex>
								<div style={{visibility: userHidden && "hidden", height: userHidden && 0}}>
									{hasIncomplete && <DynamicTable data={incomplete} columns={[
										{Header: 'Why?', Cell: props => this.renderWhyIncomplete(props.original), width: 175},
										{ Header: 'First Name', accessor: 'first_name', width: 150, },
										{ Header: 'Last Name', accessor: 'last_name', width: 150, },
										{ Header: 'Phone', accessor: 'phone', width: 150, },
										{ Header: 'Email', accessor: 'email', width: 200, },
										{ Header: 'Donor Type', accessor: 'donor_type', width: 150, Cell: props => this.renderDonorType(props.value)},
										{ Header: 'Daily Donation', accessor: 'daily_donation_sum', width: 250, Cell: props => props.value === 0 ? '' : currencyFormat(props.value)},
										{ Header: 'Current Balance', width: 200, Cell: props => currencyFormat(props.original.roundups_balance + props.original.daily_donations_balance)},
										{ Header: 'Donation Account', accessor: 'has_payment_account', width: 175, Cell: props => props.value ? 'Yes' : ''},
										{ Header: 'Tracked Account', accessor: 'has_tracked_accounts', width: 175, Cell: props => props.value ? 'Yes' : ''},
										{ Header: "Monthly Minimum", accessor: 'monthly_minimum', Cell: props => props.value === 0 ? '' : currencyFormat(props.value)},
									]}
									paginate
									maxRows={20}
									/>
									}
								</div>
							</Card>
						</Flex>}
							{hasComplete &&
						<Flex row marginBottom="2em" width="100%">
							<Card padding="1em" width="100%" marginBottom={donorDonations && donorDonations.length < 25 && "3.3em"}>
								<Flex row justifyContent="space-between">
									<Flex style={{flex: 5}}>
										<div style={{display: 'flex'}}>
											<Heading as="h3" margin="0 0 0.5em 0">{complete && complete.length} Complete Donor Accounts</Heading>
											{pending && <Loader/>}
										</div>
										{!pending &&
											<Button style={{marginLeft: 10, maxWidth: 150}}
												onClick={()=>this.setState({donorHidden: !donorHidden})}
											>{donorHidden ? "Show" : "Hide"}</Button>
										}
									</Flex>
									{complete && complete.length > 0  && <CSVLink
										filename={`${organization && organization.title.replace(/ /g, '_')}_Flourish_complete_users.csv`}
										style={{marginLeft: 15,
											fontFamily: theme.fonts.primary,
											textDecoration: 'none'
										}}
										data={complete}>
										Download
									</CSVLink>}
								</Flex>

								<div style={{visibility: donorHidden && "hidden", height: donorHidden && 0}}>
									{complete && complete.length > 0 && <DynamicTable data={complete} columns={[
										{ Header: 'First Name', accessor: 'first_name', width: 150, },
										{ Header: 'Last Name', accessor: 'last_name', width: 150, },
										{ Header: 'Phone', accessor: 'phone', width: 150, },
										{ Header: 'Email', accessor: 'email', width: 200, },
										{ Header: 'Donor Type', accessor: 'donor_type', width: 150, Cell: props => this.renderDonorType(props.value)},
										{ Header: 'Current Balance', width: 200, Cell: props => currencyFormat(props.original.roundups_balance + props.original.daily_donations_balance)},
										{ Header: 'Daily Donation', accessor: 'daily_donation_sum', width: 250, Cell: props => props.value === 0 ? '' : currencyFormat(props.value)},
										{ Header: 'Donation Account', accessor: 'has_payment_account', width: 175, Cell: props =>  props.value ? 'Yes' : ''},
										{ Header: 'Tracked Accounts', accessor: 'has_tracked_accounts', width: 175, Cell: props => props.value ? 'Yes' : ''},
										{ Header: "Monthly Minimum", accessor: 'monthly_minimum', Cell: props => props.value === 0 ? '' : currencyFormat(props.value)},
									]}
									paginate
									maxRows={20}
									/>}
								</div>
							</Card>
						</Flex>}
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

Donors.propTypes = {
	organization: PropTypes.object,
	token: PropTypes.string,
	reToken: PropTypes.string,
	pending: PropTypes.bool,
	refreshToken: PropTypes.func,
	users: PropTypes.array,
	getUsers: PropTypes.func,
	error: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		stats: state.statistics,
		users: state.statistics.users,
		token: state.authState.accessToken,
		reToken: state.authState.refreshToken,
		pending: state.statistics.pending,
		error: state.statistics.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (token) => dispatch(getUsers(token)),
		refreshToken: (token) => dispatch(refreshToken(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Donors)
